<template>
  <v-list v-if="model.errors">
    <template
      v-for="(key, index) in Object.keys(model.errors)"
    >
      <div
        :key="key"
        v-if="model.errors[key].length"
      >
        <v-subheader v-if="errorMessages[key]">
          {{ errorMessages[key] }}
        </v-subheader>

        <v-list-item-group>
          <v-list-item
            v-for="error in model.errors[key]"
            :key="key + (key === 'others' ? error : error.account_id)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-if="key === 'others'"
                class="text-subtitle-2"
              >
                {{ error }}
              </v-list-item-title>
              <profile-chip
                v-else
                :data="error"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-divider
          v-if="index < Object.keys(model.errors).length - 1"
          class="my-3"
        />
      </div>
    </template>
  </v-list>
</template>

<script>
// Import children components
const ProfileChip = () => import('@/blocks/common/ProfileChip.vue')

// Export the SFC
export default {
  // Name of the component
  name: 'ErrorList',

  // Register child components
  components: {
    ProfileChip
  },

  // Accept incoming data from parent
  props: {
    model: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    // Map for error messages
    errorMessages: {
      hidden_followers: 'Following profiles have hidden their followers from public view',
      account_not_found: 'We could not find audience data for following profiles',
      updating_audience: 'We are updating the audience data for following profiles',
      others: 'Other errors occurred while fetching audience data'
    }
  })
}
</script>